.inpunt-border-none .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before {
    display: none !important;
}

.fs-16px {
    font-size: 16px !important;
}

.fs-13px {
    font-size: 13px !important;
}

.m-0px {
    margin: 0px !important;
}

.mt-20px {
    margin-top: 20px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.mt-5px {
    margin-top: 5px !important;
}

.mb-5px {
    margin-bottom: 5px !important;
}

.symb {
    font-weight: 700;
    color: #000;
    font-size: 20px;
}

.d-flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.practice-settings {

    padding: 0.75rem 1.25rem;
    z-index: 1;
    color: #495057;
    border: 1px solid rgba(0, 0, 0, .125);
    border-bottom: 0px;
    border-radius: 5px;
    text-decoration: none;
}

.practice-settings:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
}

.practice-settings:hover {
    background-color: #f8f9fa;
}

.practice-settings.radio-btn label {
    font-size: 15px !important;
}

.danger-zone {
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.link {
    color: #0056b3 !important;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.add-profile-form .css-1gsv261 {
    border: none !important
}

/* .add-profile-tabs .MuiButtonBase-root {
    background-color: #f8f9fa !important;
    color: #1976d2 !important;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: none !important;
}

.add-profile-tabs .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    display: none !important;

}

.add-profile-tabs .MuiButtonBase-root.Mui-selected {
    background-color: #f8f9fa !important;
    color: #1976d2 !important;
    border: 1px solid #1976d2 !important;
    border-bottom: none !important;

} */